//these represent variables that will be used in the front end.

//header and body color
$header-color: #353535;
$body-color: #515151;
$body-color-light: #979797;

//font weight
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

//anchor color
$anchor-color: #47b8e0;
$anchor-font-weight: $font-weight-bold;

//fonts
$font-family-header: 'Raleway', sans-serif;
$font-family-body: 'Lato', sans-serif;

//susy grid system defined

$susy: (
    columns: 12,
    gutters: .25,
    //container width
    container: 960px
)
