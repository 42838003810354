body {
  margin: 0;
  //60px top and bottom and 0 left and right
  padding: 0px 0;
  line-height: 1.9;
  text-rendering: optimizeLegibility;
}


h1,
h2,
h3,
h4,
h5 {
    margin-top: 0;
}
