.tag {
    display: inline-block;
    background: #e5e5e5;
    text-decoration: none;
    //last 5px was 15px
    padding: 5px 5px;
    font-size: .9em;
    font-weight: $font-weight-normal;
    color: $body-color-light;
    margin-right: 10px;

}
