
.image-header {
  //ensures image will not expand the width of the page
  width: 100%;
  max-height: 600px;
  // overflow hidden cuts off any remaining portion of the image that is beyond 400px;
  overflow: hidden;
  margin-bottom: 60px;
  //uncomment below to filter = reduces brightness of the picture
  // filter: brightness(50%);
}

.image-header__image {
  width: 100%;
}

.article {
  margin-bottom: 60px;

}

.article__header {
  font-size: 2em;
  font-weight: $font-weight-extra-bold;
  margin-bottom: 30px;
}

.article__subheader {
  font-size: 1.4em;
  font-family: $font-family-body;
  font-weight: $font-weight-medium;
  margin-bottom: 60px;

}

.artcle__body {
  font-size: 1.2em;
  font-weight: $font-weight-normal;
  margin-bottom: 60px;

}

@include susy-breakpoint(720px) {
  .article {
    @include span(8 of 12);
    @include push(2);
  }
}
