// Breakpoint Integration
// ======================

$susy-media: () !default;
$susy-media-fallback: false !default;

$_susy-media-context: ();


// Susy Breakpoint
// ---------------
// Change grids at different media query breakpoints.
// - $query     : <min-width> [<max-width>] | <property> <value> | <map>
// - $layout    : <settings>
// - $no-query  : <boolean> | <selector>
@mixin susy-breakpoint(
  $query,
  $layout: false,
  $no-query: $susy-media-fallback
) {
  @include susy-media-router($query, $no-query) {
    @if $layout {
      @include with-layout($layout) {
        @content;
      }
    } @else {
      @content;
    }
  }
}


// Susy Media
// ----------
// - $query: <min-width> [<max-width>] | <property> <value>
// - $no-query: <boolean> | <selector>
@mixin susy-media(
  $query,
  $no-query: $susy-media-fallback
) {
  $old-context: $_susy-media-context;
  $name: if(map-has-key($susy-media, $query), $query, null);
  $query: susy-get-media($query);
  $query: susy-parse-media($query);

  @include susy-media-context($query, $name);

  @if $no-query and type-of($no-query) != string {
    @content;
  } @else {
    @media #{susy-render-media($query)} {
      @content;
    }

    @if type-of($no-query) == string {
      #{$no-query} & {
        @content;
      }
    }
  }

  @include susy-media-context($old-context, $clean: true);
}


// Media Router
// ------------
// Rout media arguments to the correct mixin.
@mixin susy-media-router(
  $query,
  $no-query: $susy-media-fallback
) {
  @if susy-support(breakpoint, (mixin: breakpoint), $warn: false) {
    @include breakpoint($query, $no-query) {
      @content;
    }
  } @else {
    @include susy-media($query, $no-query) {
      @content;
    }
  }
}


// Update Context
// -------------
// Set the new media context
@mixin susy-media-context(
  $query,
  $name: null,
  $clean: false
) {
  $query: map-merge((name: $name), $query);

  @if $clean {
    $_susy-media-context: $query !global;
  } @else {
    $_susy-media-context: map-merge($_susy-media-context, $query) !global;
  }
}


// Media Context
// -------------
// Return the full media context, or a single media property (e.g. min-width)
@function susy-media-context(
  $property: false
) {
  @if $property {
    @return map-get($_susy-media-context, $property);
  } @else {
    @return $_susy-media-context;
  }
}


// Get Media
// ---------
// Return a named media-query from $susy-media.
// - $name: <key>
@function susy-get-media(
  $name
) {
  @if map-has-key($susy-media, $name) {
    $map-value: map-get($susy-media, $name);
    @if ($name == $map-value) {
      $name: $map-value;
    } @else {
      $name: susy-get-media($map-value);
    }
  }

  @return $name;
}


// Render Media
// ------------
// Build a media-query string from various media settings
@function susy-render-media(
  $query
) {
  $output: null;
  @each $property, $value in $query {
    $string: null;

    @if $property == media {
      $string: $value;
    } @else {
      $string: '(#{$property}: #{$value})';
    }

    $output: if($output, '#{$output} and #{$string}', $string);
  }

  @return $output;
}


// Parse Media
// -----------
// Return parsed media-query settings based on shorthand
@function susy-parse-media(
  $query
) {
  $mq: null;
  @if type-of($query) == map {
    $mq: $query;
  } @else if type-of($query) == number {
    $mq: (min-width: $query);
  } @else if type-of($query) == list and length($query) == 2 {
    @if type-of(nth($query, 1)) == number {
      $mq: (
        min-width: min($query...),
        max-width: max($query...),
      );
    } @else {
      $mq: (nth($query, 1): nth($query, 2));
    }
  } @else {
    $mq: (media: '#{$query}');
  }

  @return $mq;
}
