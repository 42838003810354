body {
  font-family: $font-family-body;
  color: $body-color;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-header;
  color: $header-color;
}
