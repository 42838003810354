.container {
  @include container;
  //padding top is 0 and on the lest it is 40
  padding: 0 40px;
}

.content {
  @include span(12);
}

.sidebar {
  @include span(12);
}

//susy break point for mobile devices
@include susy-breakpoint(960px) {

  .sidebar {
      @include span(4 last);
  }

  .content {
    @include span(8 of 12);
  }
}
